





































































































































































.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}

.modal {
  color: #ffffff;
  h4 {
    color: #ffffff;
  }
}

.modal-body {
  p {
    color: #ffffff;
  }
}
.modal-content {
 p {
   color: #ffffff;
 }

  background-color: #2c2f46;
  /*ul, li {*/
    /*color: #000000 !important;*/
  /*}*/
}

