.tim-row{
    margin-bottom: 20px;
}

.tim-white-buttons {
    background-color: #777777;
}
.typography-line{
    padding-left: 25%;
    margin-bottom: 35px;
    position: relative;
    display: block;
    width: 100%;
}
.typography-line span{
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    position: absolute;
    width: 260px;
    text-transform: none;
}
.tim-row{
    padding-top: 60px;
}
.tim-row h3{
    margin-top: 0;
}

.offline-doc .page-header:before {
  content: "";
  background: rgba(0,0,0,.75);
}

.offline-doc .page-header{
  display: flex;
  align-items: center;
}

.offline-doc .footer{
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #fff;
  z-index: 1;
}

#map {
  position: relative;
  width: 100%;
  height: 100vh;
}

.route307-title {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 2.2rem;
  text-transform: capitalize;
  line-height: 2.2rem;
  font-display: swap;
  color: #000000 !important;
}

.route307-subtitle {
  font-family: 'Fira Sans';
  color: #3c3e39 !important;
  font-size: 1.2rem;
  line-height: 140%;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

.drive {
  animation-name: run;
  animation-duration: 6s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  width: 100px;
}


header {
  background-color:rgba(33, 33, 33, 0.9);
  color:#ffffff;
  display:block;
  font: 14px/1.3 Arial,sans-serif;
  height:50px;
  position:relative;
  z-index:5;
}

header a, a:visited {
  text-decoration:none;
  color: #C5FBEE;
}

@keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-webkit-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-moz-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-ms-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

@keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-webkit-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-moz-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-ms-keyframes move-clouds-back {
  from {background-position: 0;}
  to {background-position:10000px 0;}
}

.stars, .twinkling, .clouds {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  display:block;
}

.stars {
  background:#000 url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;
  z-index:0;
}

.twinkling{
  background:transparent url(http://www.script-tutorials.com/demos/360/images/twinkling.png) repeat top center;
  z-index:1;

  -moz-animation:move-twink-back 200s linear infinite;
  -ms-animation:move-twink-back 200s linear infinite;
  -o-animation:move-twink-back 200s linear infinite;
  -webkit-animation:move-twink-back 200s linear infinite;
  animation:move-twink-back 200s linear infinite;
}

.clouds{
  background:transparent url(http://www.script-tutorials.com/demos/360/images/clouds3.png) repeat top center;
  z-index:3;

  -moz-animation:move-clouds-back 200s linear infinite;
  -ms-animation:move-clouds-back 200s linear infinite;
  -o-animation:move-clouds-back 200s linear infinite;
  -webkit-animation:move-clouds-back 200s linear infinite;
  animation:move-clouds-back 200s linear infinite;
}

#game-map {
  border: 3px solid #c0f0ab;
}
@keyframes run {
  0% { transform: translate(255px,  0px);  }
  80%  { transform: translate(-60px,  0); }
  90%  { transform: translate( -100px, 0); }
  100%  { transform: translate(-150px,0); }
}


@keyframes floating {
  0% { transform: translate(0,  0px);  border-radius: 10px}


  80%  { transform: rotate(10deg); }

  50%  { transform: translate(0, 5px); }
  80%  { transform: rotate(-10deg); }

  100%   { transform: translate(0, -0px); }
}

.transparent-card {
  background-color: transparent;
}


p {
  font-weight: 400;
  line-height: 1.3rem;
  font-style: normal !important;
  color: #202421 !important;
  font-display: swap;
}




li {
  margin: 10px;
}

.action-button {
  text-align: center;
  color: black !important;
  margin: 4px;
  box-shadow: 0px 0px 0px 1px #242424 inset, 0px 4px 0px 0px #1d1d1d, 0px 5px 0px 0px #242424;
  font-size: 1.2rem;
  font-family: Helvetica;
  font-weight: bolder;
  background-color: #434343 !important;
  width: 90%;

}

.brand-title {
  color: black;
  font-size: 10px;
  letter-spacing: 5px;
background-image: linear-gradient(to right, #5fe2c1, #f3e73f)
}

.btn-link {
  color: black !important;
  text-transform: uppercase;
  &.active {
    background-color: transparent !important;
    text-decoration: underline;
  }
}
